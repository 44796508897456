import { NgModule } from "@angular/core";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import "hammerjs";

import { CtbcMainComponent } from "./components/core/ctbc-main.component";
import { HttpLoaderFactory, SharedModule } from "booking-app-v2/shared/shared.module";
import { CtbcRoutingModule } from "./ctbc.routing.module";
import {
  GoogleAnalyticsComponent,
  PageNotFoundComponent,
} from "booking-app-v2/shared/components/core";
import { SharedHeaderModule } from "booking-app-v2/shared/components/shared-header/shared-header.module";
import { SharedFooterModule } from "booking-app-v2/shared/components/shared-footer/shared-footer.module";
import { LoginDialogModule } from "booking-app-v2/shared/components/dialog/login-dialog/login-dialog.module";
import { RegisterDialogModule } from "booking-app-v2/shared/components/dialog/register-dialog/register-dialog.module";
import { ForgetPasswordDialogModule } from "booking-app-v2/shared/components/dialog/forget-password-dialog/forget-password-dialog.module";
import { SimpleDialogModule } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.module";
import {
  InvalidCreditCardDialogModule,
} from "booking-app-v2/shared/components/dialog/invalid-credit-card-dialog/invalid-credit-card-dialog.module";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    SharedModule,
    SharedHeaderModule,
    SharedFooterModule,
    LoginDialogModule,
    RegisterDialogModule,
    ForgetPasswordDialogModule,
    InvalidCreditCardDialogModule,
    CtbcRoutingModule,
    SimpleDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
  ],
  declarations: [
    CtbcMainComponent,
    GoogleAnalyticsComponent,
    PageNotFoundComponent,
  ],
  bootstrap: [CtbcMainComponent],
  exports: [],
})

export class CtbcModule {}
