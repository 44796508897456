import '../../assets/javascripts/public-path';
// For polyfills-v2:
// 1. Use ES6 'import' instead of 'require', so it will be available for node_modules as well
// 2. Must be placed at the top of the file before we import everything else
// 3. the filename must contain .ts extension
import '../../assets/javascripts/polyfills-v2.ts';
// Import regeneratorRuntime so it's globally available to babel make IE compatible
import 'regenerator-runtime/runtime';
import './ctbc_v2.scss';

require('../../assets/javascripts/booking-app-v2/whitelabels/ctbc/ctbc.module');
require('../../../vendor/assets/javascripts/i18n/dayjs/en')
require('../../../vendor/assets/javascripts/i18n/dayjs/tw')

import { enableProdMode } from '@angular/core';
import 'reflect-metadata';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CtbcModule } from "../../assets/javascripts/booking-app-v2/whitelabels/ctbc/ctbc.module";

if (process.env.NODE_ENV !== 'development') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(CtbcModule, { preserveWhitespaces: true });
