import { Injectable } from "@angular/core";

// Services to be override/initialized
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

// Constants
import { TENANT } from "booking-app-v2/shared/types";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";

@Injectable({
  providedIn: "root",
})
export class CtbcInitializationService {

  constructor(
    private appSettingsService: AppSettingsService,
    private currenciesService: CurrenciesService,
    private bootstrapDataService: BootstrapDataService,
  ) { }

  init(): void {
    this.appSettingsService.tenant = TENANT.CTBC;
    this.appSettingsService.iconFormat = "svg";
    // angular-v2-todo decide if we still need this dateIcon appSetting since we are using material datepicker now
    // this.appSettingsService.dateIcon = "icon-calendar.svg";
    this.appSettingsService.mobileResultPopupConfig = {
      filterIconSource: "/ctbc/icons/icon-filter.svg",
      sortIconSource: "/ctbc/icons/icon-sort.svg",
      mapIconSource: "/ctbc/icons/icon-mobile-map.svg",
    };

    this.appSettingsService.mapboxHotelMarkerColor = "#1B1E5B";
    this.appSettingsService.mapboxHotelClusterCounterTextColor = "#FFFFFF";
    this.appSettingsService.mapboxHotelClusterColor = "rgba(27,30,91,0.7)";
    this.appSettingsService.hotelDetailsTemplateConfig.earnRewardLabel = "wl.jal.total_earn";
    this.appSettingsService.ccValidationUseCardErrorModal = true;

    this.appSettingsService.abTest = { withConfidence: "no" };
    this.appSettingsService.hasCustomLoaderFile = true;
    this.appSettingsService.useSimpleLoader = false;
    this.appSettingsService.loginUrl = "/api/ctbc/sign_in";
    this.appSettingsService.registerUrl = "/api/ctbc/register";
    this.appSettingsService.forgetPasswordUrl = "/api/ctbc/password";
    this.appSettingsService.requireLoginOnCheckout = true;
    this.appSettingsService.landingPagePointsCalculationConfig = {
      nights: 3,
      perNight: 5000,
      milesMultiplier: 0.056 / 0.3675,
    };
    this.appSettingsService.showLoyaltyProgramTnC = true;
    this.appSettingsService.prismicSettings = {
      enabled: true,
      faqs: "te-ctbc--faq",
      terms: "te-ctbc--terms",
      privacyPolicy: "te-ctbc--privacy-policy",
    };
    this.appSettingsService.serviceEmail = this.bootstrapDataService.bootstrapData.service_email;

    this.currenciesService.changeCurrency("TWD");
  }

}
