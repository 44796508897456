import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { LandingPageRoutingModule } from "../routes/landing-page-routing.module";
import { LandingPageComponent } from "../landing-page.component";
import { HotelSearchFormModule } from "booking-app-v2/shared/components/hotel-search-form/hotel-search-form.module";
import { BannerModule } from "booking-app-v2/shared/components/banner";
import { LandingPageInfoModule } from "booking-app-v2/shared/components/landing-page-info/landing-page-info.module";
import { MastheadBannerModule } from "booking-app-v2/shared/components/masthead-banner/masthead-banner.module";
import { LandingPageTilesModule } from "booking-app-v2/shared/components/landing-page-tiles/landing-page-tiles.module";

@NgModule({
  imports: [
    SharedModule,
    LandingPageRoutingModule,
    HotelSearchFormModule,
    BannerModule,
    LandingPageInfoModule,
    MastheadBannerModule,
    FormsModule,
    LandingPageTilesModule,
  ],
  declarations: [
    LandingPageComponent,
  ],
})
export class CtbcLandingPageModule {}
