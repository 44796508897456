import { Component } from "@angular/core";

import { CtbcInitializationService } from "../../services/ctbc-initialization.service";
import { AppInitializationService } from "booking-app-v2/shared/services/app-initialization.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

@Component({
  selector: "ctbc-app",
  templateUrl: "/html/whitelabel/ctbc/main",
})

export class CtbcMainComponent {

  constructor(
    private ctbcInitService: CtbcInitializationService,
    private appInitService: AppInitializationService,
    private globalData: GlobalData,
  ) {
    this.appInitService.initializeApp();
    this.ctbcInitService.init();
  }
}
