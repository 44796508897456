import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "booking-app-v2/shared/components/core";
import { AuthGuardService } from "booking-app-v2/shared/services/auth-guard.service";

const staticPagePaths: string[] = [
  "company/about_us",
  "company/ctbc-faq",
  "terms/ctbc-privacy-policy",
  "terms/ctbc-terms-conditions",
  "terms/ctbc-campaign-terms-conditions",
];

const routes: Routes = [
  {
    path: "results",
    loadChildren: () => import("../../hotels/results-page/modules/shared.results-page.module").then(m => m.SharedResultsPageModule),
  },
  {
    path: "hotels/detail",
    loadChildren: () => import("../../hotels/details-page/modules/shared.details-page.module").then(m => m.SharedDetailsPageModule),
  },
  {
    path: "hotels/checkout",
    loadChildren: () => import("../../hotels/checkout-page/modules/shared.checkout-page.module").then(m => m.SharedCheckoutPageModule),
  },
  {
    path: "bookings",
    loadChildren: () => import("../../hotels/confirmation-page/modules/shared.confirmation-page.module").then(m => m.SharedConfirmationPageModule),
  },
  {
    path: "account/booking",
    loadChildren: () => import("../../manage-bookings-page/modules/shared.manage-bookings-page.module").then(m => m.SharedManageBookingsPageModule),
    canLoad: [ AuthGuardService ],
  },
  {
    path: "account/profile",
    loadChildren: () => import("../../profile-page/modules/shared.profile-page.module").then(m => m.SharedProfilePageModule),
    canLoad: [ AuthGuardService ],
  },
  {
    path: "company/contact_us",
    loadChildren: () => import("../../contact-us-page/modules/shared.contact-us-page.module").then(m => m.SharedContactUsPageModule),
  },
  ...staticPagePaths.map(staticPagePath => ({
    path: staticPagePath,
    loadChildren: () => import("../../static-page/modules/shared.static-page.module").then(m => m.SharedStaticPageModule),
  })),
  { path: "company/faqs", redirectTo: "company/ctbc-faq" },
  { path: "terms/terms_condition", redirectTo: "terms/ctbc-terms-conditions" },
  { path: "terms/privacy", redirectTo: "terms/ctbc-privacy-policy" },
  { path: "terms/campaign_terms_condition", redirectTo: "terms/ctbc-campaign-terms-conditions" },
  {
    path: "users/password/:user_id",
    loadChildren: () => import("../../reset-password-page/modules/shared.reset-password-page.module").then(m => m.SharedResetPasswordPageModule),
  },
  {
    path: "",
    loadChildren: () => import("../../landing-page/modules/ctbc.landing-page.module").then(m => m.CtbcLandingPageModule),
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
  providers: [],
})
export class CtbcRoutingModule { }
